@import 'variables';

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 $color-accent-70;
  }

  70% {
    box-shadow: 0 0 0 10px transparent;
  }

  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}
