// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$unions-frontend-primary: mat.define-palette(mat.$indigo-palette);
$unions-frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$unions-frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$unions-frontend-theme: mat.define-light-theme((
  color: (
    primary: $unions-frontend-primary,
    accent: $unions-frontend-accent,
    warn: $unions-frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($unions-frontend-theme);

/* You can add global styles to this file, and also import other style files */

@import './styles/animation';
@import './styles/fonts';
@import './styles/global';
@import './styles/variables';
@import './styles/mixins/page-mixins';
@import './styles/mixins/font-mixins';
@import './styles/mixins/media-mixins';
@import './styles/mixins/scroll-mixins';
