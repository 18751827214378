@import 'variables';

@font-face {
  font-family: 'Days One';
  src: url('../../assets/fonts/DaysOne/DaysOne-Regular.ttf') format('truetype');

  font-weight: normal;
  font-style: normal;
}

@mixin font-h1() {
  font-size: $font-size-h1;
  line-height: $font-height-h1;
}

@mixin font-h2() {
  font-size: $font-size-h2;
  line-height: $font-height-h2;
}

@mixin font-h3() {
  font-size: $font-size-h3;
  line-height: $font-height-h3;
}

@mixin font-h4() {
  font-size: $font-size-h4;
  line-height: $font-height-h4;
}

@mixin font-h5() {
  font-size: $font-size-h5;
  line-height: $font-height-h5;
}

@mixin font-largest() {
  font-size: $font-size-largest;
  line-height: $font-height-largest;
}

@mixin font-larger() {
  font-size: $font-size-larger;
  line-height: $font-height-larger;
}

@mixin font-large() {
  font-size: $font-size-large;
  line-height: $font-height-large;
}

@mixin font-medium() {
  font-size: $font-size-medium;
  line-height: $font-height-medium;
}

@mixin font-small() {
  font-size: $font-size-small;
  line-height: $font-height-small;
}

@mixin font-minimal() {
  font-size: $font-size-minimal;
  line-height: $font-height-minimal;
}

@mixin font-header() {
  @include font-large();
  font-weight: $font-weight-bold;
}

@mixin font-medium-bold() {
  @include font-medium();
  font-weight: $font-weight-bold;
}

@mixin font-small-bold() {
  @include font-small();
  font-weight: $font-weight-bold;
}

@mixin font-label() {
  @include font-small();
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}

@mixin font-subtext() {
  @include font-small();
}

@mixin font-subtext-bold() {
  @include font-small();
  font-weight: $font-weight-bold;
}

@mixin font-truncate() {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin font-logo-text() {
  font-size: $font-size-h4;
  text-transform: uppercase;
  color: $color-default;
  font-family: 'Days One', $font-family-base;
  font-weight: $font-weight-bold;
}

@mixin font-widget-header() {
  @include font-larger();
}
